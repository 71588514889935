<template>
  <div>
    <div>
      <!-- 头部 -->
      <!-- <Header></Header> -->
      <!-- <div class="BoxHeight bbox positionr"> -->
      <!-- 收藏 -->
      <div class="likeTool textr pr20 flex justBten">
        <div class="flex">
          <el-button class="backBtn" type="primary" @click="backMini">目录</el-button>
          <el-button class="backBtn" style="width:70px;margin-left: 20px;" type="primary" @click="checkDetail">
            查看注释
          </el-button>
        </div>
        <div style="display:flex;">
          <div v-if="currFileData.bigFile == 1">
            <div v-if="downBig == '0'">
              <el-button class="backBtn" style="width:70px;margin-right:20px;" type="primary"
                @click="getOffine(1)">离线缓存</el-button>
            </div>
            <div v-if="downBig == '1'">
              <el-button class="backBtn" style="width:70px;margin-right:20px;" type="primary"
                @click="getOffine(0)">删除缓存</el-button>
            </div>
          </div>
          <div>
            <i v-if="status == false" class="el-icon-star-off f22 text999" @click="addLike"></i>
            <i v-if="status != false && typeCode == collectTypeCode" class="el-icon-star-on f26 textblue"
              @click="delLike"></i>
          </div>
        </div>
      </div>
      <div class="previewArea" v-if="'1' == isFile" v-loading="loading">
        <iframe :src="url" width="100%" height="100%" frameborder="0" ref="stfIframe">
        </iframe>
        <div class="content"></div>
      </div>
      <div class="textc previewArea" v-if="'0' == isFile">
        <video :src="url" controls="controls" controlslist="nodownload" width="100%" height="300px"></video>
      </div>

      <!-- </div> -->

      <div class="bottom" v-if="this.$route.query.pageStatus != 3">
        <div class="lastView">
          <el-button v-if="this.index != 0" type="primary" style="
            height: 30px;
            padding: 0 3px 0 3px;
            position: relative;
            margin-left: 10%;
          " @click="lastView">{{ $t("lastArtical") }}</el-button>
        </div>
        <div class="thisName">{{ typeName }}</div>
        <div class="nextViev">
          <el-button v-if="this.index + 1 != this.list.length" type="primary" style="
            height: 30px;
            padding: 0 3px 0 3px;
            position: relative;
            float: right;
          " @click="nextViev">{{ $t("nextArtical") }}</el-button>
        </div>
      </div>
      <!-- 页脚 -->
      <!-- <Footer></Footer> -->
    </div>
  </div>
</template>

<script>
var tips1 = "提示";
var tips2 = "收藏文档成功！";
var tips3 = "收藏失败:";
var tips4 = "取消收藏成功！";
var tips5 = "取消收藏失败：";
var tips6 = "无文件地址，请联系管理员！";
// import Header from "../components/commonHead.vue";
// import Footer from "../components/footer.vue";
// import wx from "weixin-js-sdk";
export default {
  // components: { Header, Footer },
  data() {
    return {
      url: "",
      isFile: "1",
      collectTypeCode: "",
      typeCode: "",
      typeName: "",
      machineType: "",
      timer: null,
      viewCode: "",
      status: false,
      content: "",
      loading: true,
      list: [],
      index: "",
      previewAreaTittle: "",
      turnPages: null,
      file: {},
      currFileData: {},
      downBig: '',
    };
  },
  methods: {
    // 查看注释
    checkDetail() {
      let that = this

      this.$router.push({ path: '/checkNodes', query: { memberCode: that.memberCode, typeCode: that.typeCode } })
    },
    //离线与取消离线
    getOffine(status) {
      let data = {}
      var that = this
      data.memberCode = that.$route.query.memberCode;
      data.objectCode = that.currFileData.objectCode
      data.status = status
      that.$http.post('/downFile/saveDownFile', data).then(res => {
        if (res.data.code == 200) {
          if (status == 1) {
            that.downBig = '1'
            that.$forceUpdate()
            that.$message.success('缓存成功');
          } else {
            that.downBig = '0'
            that.$forceUpdate()
            that.$message.success('删除成功');
          }
        } else {
          that.$message.error(res.data.message);
        }
      })
    },
    //查询list
    queryAnnexFileList() {
      var sendData = {};
      sendData.memberCode = this.$route.query.memberCode;
      sendData.objectCode = this.typeCode;
      var that = this;
      this.$http
        .post("annexFile/queryAnnexFileList", sendData)
        .then(function (res) {
          if (200 == res.data.code) {
            that.list = res.data.data.mtList
            that.showTypeData(that.typeCode, that.typeName, that.index);
          }
        });
    },

    //返回小程序
    backMini() {
      // wx.miniProgram.navigateBack({
      //   delta: 1
      // });
      // wx.miniProgram.navigateBack();
      // alert(0)
      wx.miniProgram.navigateBack({
        delta: 1, // 返回的页面数，1表示返回上一页，依次类推
        success: () => {
          //  alert(1)
        },
        fail: (err) => {
          console.error("跳回小程序页面失败", err);
          // alert(JSON.stringify(err))
        },
      });
      wx.miniProgram.postMessage({ data: { backPage: 'H5' } })

    },

    //前一篇
    lastView() {
      if (this.index == 0) {
        this.$message.error({
          message: "已经是第一页了！",
          type: "warning",
        });
      } else {
        this.index = parseInt(this.index) - 1;
        var typeCode = this.list[this.index].typeCode;
        var typeName = this.list[this.index].typeName;
        this.showTypeData(typeCode, typeName, this.index);
        this.queryCollect();
      }
      this.turnPages = 1;
    },

    //查询Url并展示
    showTypeData(typeCode, typeName, index) {
      var headUrl = "https://view.officeapps.live.com/op/view.aspx?src=";
      var index = index;

      var sendData = {};
      sendData.objectCode = typeCode;
      sendData.memberCode = this.$route.query.memberCode
      var that = this;
      that.$http.post("annexFile/queryFileListWechat", sendData).then(function (res) {
        if (200 == res.data.code) {
          // console.log('查询Url',res.data.data)
          console.log("查询Url1", res.data.data[0]);
          that.currFileData = res.data.data[0];
          that.downBig = res.data.data[0].downBig
          var file = res.data.data[0];
          if (file != undefined && file.fileUrl != undefined) {
            var tempUrl = file.fileUrl;
            // console.log(tempUrl)
            ///********* */
            if (tempUrl.indexOf(".pdf") != -1) {
              that.isFile = "1";
              that.url =
                "/static/pdf/web/viewer.html?file=" +
                encodeURIComponent(tempUrl);
              that.loading = false;
              that.previewAreaTittle = typeName;
              that.typeName = typeName;
              that.typeCode = typeCode;
            }
            if (
              tempUrl.indexOf(".xls") != -1 ||
              tempUrl.indexOf(".xlsx") != -1 ||
              tempUrl.indexOf(".docx") != -1 ||
              tempUrl.indexOf(".doc") != -1 ||
              tempUrl.indexOf(".pptx") != -1 ||
              tempUrl.indexOf(".ppt") != -1
            ) {
              // that.isFile='1'
              // that.url = headUrl + tempUrl;
              that.loading = false;
              if (that.turnPages == 1) {
                that.lastView();
              } else if (that.turnPages == 2) {
                that.nextViev();
              }
            }
            if (
              tempUrl.indexOf(".mp4") != -1 ||
              tempUrl.indexOf(".mp3") != -1
            ) {
              that.isFile = "0";
              that.url = tempUrl;
              that.loading = false;
              that.previewAreaTittle = typeName;
              that.typeName = typeName;
              that.typeCode = typeCode;
            }
            if (
              tempUrl.indexOf(".jpg") != -1 ||
              tempUrl.indexOf(".png") != -1 ||
              tempUrl.indexOf(".jpeg") != -1
            ) {
              that.isFile = "2";
              that.url = tempUrl;
              that.loading = false;
              that.previewAreaTittle = typeName;
              that.typeName = typeName;
              that.typeCode = typeCode;
            }
            that.url =
              "/static/pdf/web/viewer.html?file=" + encodeURIComponent(url);
            that.$nextTick(() => {
              var iframe = that.$refs.iframe;
              iframe.att;
              if (!(/*@cc_on!@*/ 0)) {
                iframe.onload = function () {
                  var timer = setTimeout(() => {
                    that.loading = false;
                  }, 500);
                  that.contentWindow.document.oncontextmenu = function () {
                    return false;
                  };
                  that.contentWindow.document.onkeydown = function () {
                    if (event.ctrlKey && window.event.keyCode == 67) {
                      return false;
                    }
                    if (event.ctrlKey && window.event.keyCode == 86) {
                      return false;
                    }
                  };
                };
              } else {
                iframe.onreadystatechange = function () {
                  if (iframe.readyState == "complete") {
                    var timer = setTimeout(() => {
                      that.loading = false;
                    }, 500);
                    // clearTimeout(timer);
                  }
                };
                that.contentWindow.document.oncontextmenu = function () {
                  return false;
                };
                that.contentWindow.document.onkeydown = function () {
                  if ($event.ctrlKey && window.event.keyCode == 67) {
                    return false;
                  }
                  if ($event.ctrlKey && window.event.keyCode == 86) {
                    return false;
                  }
                };
              }
            });
          } else {
            that.url = "";
            that.isFile = "";
            that.$message(tips6);
          }
        }
      });
    },

    //后一篇
    nextViev() {
      //   console.log('this.index',this.index)
      // console.log('this.list.length-1',this.list.length-1)
      if (this.index >= this.list.length - 1) {
        this.$message.error({
          message: "已经是最后一页了！",
          type: "warning",
        });
      } else {
        this.index = parseInt(this.index) + 1;
        //  console.log('向后', this.index)
        var typeCode = this.list[this.index].typeCode;
        var typeName = this.list[this.index].typeName;
        this.showTypeData(typeCode, typeName, this.index);
        this.queryCollect();
      }
      this.turnPages = 2;
    },

    changeLanguage() {
      if (this.$store.getters.language == "zh") {
        tips1 = "提示";
        tips2 = "收藏文档成功！";
        tips3 = "收藏失败:";
        tips4 = "取消收藏成功！";
        tips5 = "取消收藏失败：";
        tips6 = "无文件地址，请联系管理员！";
      }
      if (this.$store.getters.language == "en") {
        tips1 = "Prompt";
        tips2 = "Collect the document successfully!";
        tips3 = "Collection of failure";
        tips4 = "Unbookmark successfully!";
        tips5 = "Unbookmark failed:";
        tips6 = "No file address, please contact the administrator！";
      }
      if (this.$store.getters.language == "ja") {
        tips1 = "提示";
        tips2 = "ドキュメントの収蔵に成功!";
        tips3 = "コレクション失敗:";
        tips4 = "キャンセル成功!";
        tips5 = "コレクションをキャンセルするのに失敗しました:";
        tips6 = "ファイルアドレスがありません。管理者に連絡してください";
      }
    },

    //   收藏
    addLike() {
      var sendData = {};
      //  var str= this.file.fileName
      //    var temp = str.substring(0,str.lastIndexOf(".") )
      sendData.memberCode = this.$route.query.memberCode;
      sendData.typeCode = this.typeCode;
      sendData.machineType = this.$route.query.machineType;
      sendData.typeName = this.typeName;
      // sendData.fileName=temp
      //  alert(JSON.stringify(sendData))
      var that = this;
      this.$http
        .post("memberCollect/addMemberCollect", sendData)
        .then(function (res) {
          //  console.log('收藏',res.data);
          // alert(JSON.stringify(res.data))
          if (200 == res.data.code) {
            that.status = true;
            that.collectTypeCode = that.typeCode;
            that.$message(tips2);
          } else {
            that.$message(tips3);
          }
        });
    },
    // 取消收藏
    delLike() {
      var sendData = {};
      sendData.memberCode = this.$route.query.memberCode;
      sendData.typeCode = this.typeCode;
      var that = this;
      this.$http
        .post("memberCollect/deleteMemberCollect", sendData)
        .then(function (res) {
          // console.log('取消',res.data);
          if (200 == res.data.code) {
            that.status = false;
            that.collectTypeCode = that.typeCode;
            that.$message(tips4);
          } else {
            that.$message(tips5);
          }
        });
    },
    countTime() {
      var sendData = {};
      sendData.typeCode = this.typeCode;
      sendData.typeName = this.$route.query.typeName;
      sendData.viewCode = this.viewCode;
      sendData.machineType = this.$route.query.machineType;
      sendData.memberCode = this.$route.query.memberCode;
      sendData.sourceType = "1";
      var that = this;
      this.$http
        .post("memberViews/saveViewInfo", sendData)
        .then(function (res) {
          if (200 == res.data.code) {
            that.viewCode = res.data.data;
          }
        });
    },
    queryCollect() {
      var sendData = {};
      sendData.memberCode = this.$route.query.memberCode;
      sendData.typeCode = this.typeCode;
      var that = this;
      this.$http
        .post("memberCollect/queryIsCollect", sendData)
        .then(function (res) {
          if (200 == res.data.code) {
            if ("1" == res.data.data) {
              that.status = true;
              that.collectTypeCode = that.typeCode;
            } else {
              that.status = false;
            }
          }
        });
    },
    openFile() {
      // debugger
      var headUrl = "https://view.officeapps.live.com/op/view.aspx?src=";
      // var headUrl = ''
      var tempUrl = this.$route.query.url;
      // alert('url:'+this.$route.query.url)
      // this.content = this.$route.query.content;
      this.typeCode = this.$route.query.typeCode;
      this.typeName = this.$route.query.typeName;
      this.machineType = this.$route.query.machineType;
      this.list = JSON.parse(this.$route.query.list);
      this.index = this.$route.query.index;
      var typeCode = this.list[this.index].typeCode;
      var typeName = this.list[this.index].typeName;
      if (
        this.machineType == "" ||
        this.machineType == null ||
        this.machineType == undefined
      ) {
        this.showTypeData(typeCode, typeName, this.index);
      }
      var that = this;

      if (tempUrl.indexOf(".pdf") != -1) {
        // that.url = tempUrl;
        console.log(tempUrl);
        that.url = "/static/pdf/web/viewer.html?file=" + tempUrl;
        // debugger
      }
      if (
        tempUrl.indexOf(".xls") != -1 ||
        tempUrl.indexOf(".xlsx") != -1 ||
        tempUrl.indexOf(".docx") != -1 ||
        tempUrl.indexOf(".pptx") != -1 ||
        tempUrl.indexOf(".ppt") != -1 ||
        tempUrl.indexOf(".doc") != -1
      ) {
        that.url = headUrl + tempUrl;
      }
      if (tempUrl.search(".mp4") != -1 || tempUrl.search(".mp3") != -1) {
        that.isFile = "0";
        that.url = tempUrl;
      }
    },
    stateChange() {
      this.loading = false;
    },
  },
  created() {
    this.changeLanguage();
    // this.openFile();
    // debugger
    this.typeCode = this.$route.query.typeCode;
    this.typeName = this.$route.query.typeName;
    this.machineType = this.$route.query.machineType;
    this.memberCode = this.$route.query.memberCode;
    // this.list = JSON.parse(this.$route.query.list);
    this.index = this.$route.query.index;
    // this.showTypeData(this.typeCode, this.typeName, this.index);

    //查询list
    this.queryAnnexFileList();
    this.queryCollect();
    clearInterval(this.timer);
    this.countTime();
    this.countDownTimer = setInterval(this.countTime, 60 * 1000);
    this.$nextTick(() => {
      // 禁用右键
      document.oncontextmenu = new Function("event.returnValue=false");
      // 禁用选择
      document.onselectstart = new Function("event.returnValue=false");
    });
  },
  mounted() {
    const iframe = this.$refs.stfIframe;
    const that = this;
    iframe.onload = function () {
      that.stateChange();
    };
  },
};
</script>

<style scoped>
.previewArea {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: calc(100vh - 146px);
  width: 100vw;
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  font-size: 16px;
}

.likeTool {
  height: 46px;
  line-height: 52px;
}

/* 翻页 */
.bottom {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: space-around;
  /* margin-left: 34%; */
}

.lastView {
  width: 30%;
  /* background: lightpink; */
}

.justBten {
  justify-content: space-between;
}

.thisName {
  width: 30%;
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  /* background: #47C297; */
}

.nextViev {
  width: 25%;
  /* background: #5EC95E; */
}

.backBtn {
  height: 25px;
  width: 50px;
  margin-left: 3%;
  text-align: center;
  padding: 0 !important;
  margin-top: 10px;
}
</style>